const state = {
  menus: {},
  modules: [],
  currentMenu: {
    selectedKeys: [], //当前选择的菜单id
    openKeys: [], //需要展开的菜单
    moduleId: null, //模块id
  },
  isCollapse: false, //菜单是否折叠
  storeBuildTodoCount: 0, //开店项目的待办数量
  abnormalNodeCount: 0, //异常节点数量
};
const mutations = {
  //设置所有菜单列表
  setMenus: (state, data) => {
    state.menus = data;
  },

  setModules: (state, data) => {
    state.modules = data;
  },

  setCurrentMenu: (state, data) => {
    state.currentMenu = data;
  },
  setIsCollapse: (state, data) => {
    state.isCollapse = data;
  },
  setStoreBuildTodoCount: (state, data) => {
    state.storeBuildTodoCount = data;
  },
  setAbnormalNodeCount: (state, data) => {
    state.abnormalNodeCount = data;
  },
};
const actions = {};
export default {
  state,
  mutations,
  actions,
};
